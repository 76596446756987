import React from 'react';
import ReactHlsPlayer from 'react-hls-player/dist';
import './App.css';

function App() {
  const playerRef = React.createRef<HTMLVideoElement>();
  const videoId = window.location.pathname.substring(1);
  const videoUrl = `https://vz-0bca71d6-d81.b-cdn.net/${videoId}/playlist.m3u8`;

  return (
    <div className="App">
      <ReactHlsPlayer
        src={videoUrl}
        autoPlay={true}
        controls={true}
        className="player"
        playerRef={playerRef}/>
    </div>
  );
}

export default App;
